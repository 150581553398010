import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Loading, colors, Toggle, ToggleLink } from '@commonsku/styles'
import _ from 'lodash';
import { round } from '../../utils';
import { window } from '../../global';
import {
  selectByOptions, months, quarters,
  currentMonth, currentYear, currentQuarter, calcChartHeight,
  prevMonth, prevQuarter, fullMonths, transformVal, monthsOrdered
} from './utils'
import {
  colorPalette, chartColor, createVerticalBulletImageBarChart, createBarChart, createDonutChart,
  createPieChart, createBulletImageBarChart, createLineChart, getChartNumberFormatOption,
} from './utils/chart_utils';
import { modifyExport } from './utils/supplier_charts_utils';
import { getSupplierPOStats, getSupplierEventStats, getSupplierProductsStats , getSupplierPresalesStats} from './selectors';
import WidgetCardBox, { WidgetTitle } from './WidgetCardBox';
import { Chart, Select } from '../helpers';

const _colorPallet = [...colorPalette];
_colorPallet[0] = colors.special1;
_colorPallet[1] = colors.cta;
_colorPallet[2] = colors.primary;
const totalsChartsColors = _colorPallet.map(chartColor);

const TitleSelect = ({ title, highlightText, options=[], value=null, onChange=null }) => {
  const [val, setVal] = React.useState(value);

  return (
    <span style={{color: '#0f3d51', fontSize: '1em'}}>
      {title} {highlightText} 
          <Toggle style={{marginLeft: '2px'}}>
            <ToggleLink selected = {val == options[0].value} onClick={(v)=>{
               setVal(options[0].value);
               onChange && onChange(options[0].value);
            }}>
              {options[0].label}
            </ToggleLink>
            <ToggleLink  selected = {val == options[1].value}  onClick={(v)=>{
               setVal(options[1].value);
               onChange && onChange(options[1].value);
            }} >
              {options[1].label}
            </ToggleLink>
          </Toggle>
    </span>
  );
};

const getWidgetTitle = (type, totals_by, defaultTitle='') => (
  defaultTitle
    ? defaultTitle
    : type.includes('SUPPLIER_POS')
      ? (totals_by === "tenant"
      ? "Top Distributors - "
      : totals_by === "order_rep"
      ? "Top Order Reps"
      : totals_by === "order_client_rep"
      ? "Top Client Reps"
      : totals_by === "tags"
      ? "Tagged Purchase Orders"
      : totals_by === "tenants_count"
      ? "Percentage of Distributors with POs"
      : "Purchase Orders")
    : type.includes('SUPPLIER_EVENTS')
    ? (totals_by === "tenant"
      ? "Distributor Engagement - "
      : totals_by === "product"
      ? "Most Quoted Products - "
      : totals_by === "tags"
      ? "Tagged Engagement"
      : totals_by === "industry"
      ? "Projects by Client Industry - "
      : totals_by === "events"
      ? "Projects by Event Type - "
      : totals_by === "tenants_count"
      ? "Percentage of Distributors Engaged"
      : "Engagement")
    : type.includes('SUPPLIER_PRODUCTS') 
      ? "Most Sold Products - "
      : ''
);

function PercentageOfDistributorsChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions, statsData , showPopup, isSparkline, totalsChart, titleSelect} = props;
  const companyData = _.get(statsData, `totals_by_totals.data`, false);
  const years = _.get(statsData, `totals_by_tenants_count.years`, false);
  //here data being manipulated for Percentage of Distributors with POs
  const data = React.useMemo(() => { const _data =_.map(_.values(chartData), v => {
    const result = {...v};
    const found = companyData[v.group_by];
    if (found) {
      years.map((y,i) => {
       let k = i+1;
        if (_.has(v, `y${k}`)) {
          const val = v[`y${k}_totals`] == 0 ? 0
                    : totals_by === 'tenants_count'
                    ? v[`y${k}_tenant_counts`] == 0 ? 0
                   : round(parseFloat(found[`y${k}_tenant_counts`] / v[`y${k}_tenant_counts`]), 2).toFixed(2)
                    : round(parseFloat(found[`y${k}_totals`] / v[`y${k}_totals`]), 2).toFixed(2);
          result[`y${k}_percentage`] = val;
          result['totals_percentage'] = val;
          result['group_by_percentage'] = v.group_by;
          result[`y${k}_totals_percentage`] = val;
          result[`y${k}_group_by_percentage`] = v[`y${k}_group_by`];
          result[`company_y${k}`] = found[`y${k}`];
          if (totals_by === 'tenants_count') {
            result[`company_y${k}_totals`] = found[`y${k}_tenant_counts`];
          } else {
            result[`company_y${k}_totals`] = found[`y${k}_totals`];
          }
          result[`company_y${k}_group_by`] = found[`y${k}_group_by`];
        }
      });
   // }
    } else {
      result['y1_percentage'] = 0;
      result['y2_percentage'] = 0;
      result['y3_percentage'] = 0;
    }
    return result;
  }
  );
 return{
  data: group_by === 'month'
  ? _.orderBy(_data, [v => months.indexOf(v.group_by.split(' ')[0])])
  : group_by === 'quarter'
  ?  _.orderBy(_data,[v=>quarters.indexOf(v.group_by.split(' ')[0]),v=>(v.group_by.split(' ')[1])],['asc','desc'])
  : _data

 }}, [showPopup, chartData, companyData, totals_by, division_id]);
  return (<>
    <WidgetCardBox id={cardId} colSizes={colSizes} components={{ Title: titleSelect
        ? <TitleSelect title={title} 
            {...titleSelect}
          />
        : <WidgetTitle startText={title} />
      }}>
      <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          data={data.data}
          height={isSparkline ? "220px" : "280px"}
          isExportable={true}
          modifyExport={modifyExport({cardId, totals_by, group_by, formatNumberType,fileName: title })}
          // onChartReady={totalsChart({isSparkline, thisYr: true, isPercentage: true, forceLegend: true})}
          onChartReady={totalsChart({isSparkline: false, thisYr: false, isPercentage: true})}
        />
      : null}</Col>
    </WidgetCardBox>
  </>);

}

function TopDistributorsChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions, show_previous, prevHighlightText} = props;
  const data = React.useMemo(() => {
      const current = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr1_totals`]), v => ({
            year: group_by === 'year' ? v[`gr1`] : v[`gr1_group_by`].split(' ')[2],
            group_by: v[`gr1`],
            totals: _.parseInt(v[`gr1_totals`]),
            [`${totals_by}_name`]: v[`gr1_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr1_${totals_by}_id`],
            ...(v[`gr1_subData`] && {gr_subData : v[`gr1_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const prev = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr2_totals`]), v => ({
          year: group_by ==='year' ? v[`gr2`] : v[`gr2_group_by`].split(' ')[2],
            group_by: v[`gr2`],
            totals: _.parseInt(v[`gr2_totals`]),
            [`${totals_by}_name`]: v[`gr2_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr2_${totals_by}_id`],      
            ...(v[`gr2_subData`] && {gr_subData : v[`gr2_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const current_others_idx = current.findIndex(v => v[`${totals_by}_name`] === 'Others');
      const prev_others_idx = prev.findIndex(v => v[`${totals_by}_name`] === 'Others');
      return {
        current: [
          ...(current_others_idx > -1 ? current.slice(0, current_others_idx) : []),
          ...(current_others_idx > -1 ? current.slice(current_others_idx+1) : current),
          ...(current_others_idx > -1 ? [current[current_others_idx]] : []),
        ],
        prev: [
          ...(prev_others_idx > -1 ? prev.slice(0, prev_others_idx) : []),
          ...(prev_others_idx > -1 ? prev.slice(prev_others_idx+1) : prev),
          ...(prev_others_idx > -1 ? [prev[prev_others_idx]] : []),
        ],
      };
    }, [chartData, division_id]);
    return (<WidgetCardBox colSizes={{xs: 12, lg: 12}}  >
      <WidgetCardBox id={cardId} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle startText={title} endText={highlightText} />
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.current}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId, formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + highlightText) })}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              clickableLegend: false,
              mousePointer: true,
              onClickLegend: (e) => {
                const tenant_id =  e.target.dataItem && e.target.dataItem.dataContext && e.target.dataItem.dataContext._dataContext ? 
                e.target.dataItem.dataContext._dataContext.tenant_id : '';
                if (totals_by === 'tenant' && tenant_id && tenant_id.toLowerCase() !== 'others') {
                  window.open(`/distributor.php?id=${tenant_id}`, '_blank');
                }
              },
              onClickSlice: (e) => {
                const tenant_id =  e.target.dataItem && e.target.dataItem.dataContext ? e.target.dataItem.dataContext.tenant_id : '';
                if (totals_by === 'tenant' && tenant_id && tenant_id.toLowerCase() !== 'others') {
                   window.open(`/distributor.php?id=${tenant_id}`, '_blank');
                }
              },
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox>
      {show_previous ? <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle color="cta" startText={title} endText={prevHighlightText} />,
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.prev}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId: cardId+'prev', formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + prevHighlightText)})}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              mousePointer: true,
              onClickLegend: (e) => {
                const tenant_id =  e.target.dataItem && e.target.dataItem.dataContext && e.target.dataItem.dataContext._dataContext  ? 
                e.target.dataItem.dataContext._dataContext.tenant_id : '';
                if (totals_by === 'tenant' && tenant_id && tenant_id.toLowerCase() !== 'others') {
                  window.open(`/distributor.php?id=${tenant_id}`, '_blank');
                }
              },
              onClickSlice: (e) => {
                const tenant_id =  e.target.dataItem && e.target.dataItem.dataContext ? e.target.dataItem.dataContext.tenant_id : '';
                if (totals_by === 'tenant' && tenant_id && tenant_id.toLowerCase() !== 'others') {
                  window.open(`/distributor.php?id=${tenant_id}`, '_blank');
                }
              },
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox> : null}
    </WidgetCardBox>)
};

function TopIndustriesChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions, show_previous, prevHighlightText} = props;
  const data = React.useMemo(() => {
      const current = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr1_totals`]), v => ({
            year: group_by === 'year' ? v[`gr1`] : v[`gr1_group_by`].split(' ')[2],
            group_by: v[`gr1`],
            totals: _.parseInt(v[`gr1_totals`]),
            [`${totals_by}_name`]: v[`gr1_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr1_${totals_by}_id`],
            ...(v[`gr1_subData`] && {gr_subData : v[`gr1_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const prev = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr2_totals`]), v => ({
          year: group_by ==='year' ? v[`gr2`] : v[`gr2_group_by`].split(' ')[2],
            group_by: v[`gr2`],
            totals: _.parseInt(v[`gr2_totals`]),
            [`${totals_by}_name`]: v[`gr2_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr2_${totals_by}_id`],      
            ...(v[`gr2_subData`] && {gr_subData : v[`gr2_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const current_others_idx = current.findIndex(v => v[`${totals_by}_name`] === 'Others');
      const prev_others_idx = prev.findIndex(v => v[`${totals_by}_name`] === 'Others');
      return {
        current: [
          ...(current_others_idx > -1 ? current.slice(0, current_others_idx) : []),
          ...(current_others_idx > -1 ? current.slice(current_others_idx+1) : current),
          ...(current_others_idx > -1 ? [current[current_others_idx]] : []),
        ],
        prev: [
          ...(prev_others_idx > -1 ? prev.slice(0, prev_others_idx) : []),
          ...(prev_others_idx > -1 ? prev.slice(prev_others_idx+1) : prev),
          ...(prev_others_idx > -1 ? [prev[prev_others_idx]] : []),
        ],
      };
    }, [chartData, division_id]);
    return (<WidgetCardBox colSizes={{xs: 12, lg: 12}}  >
      <WidgetCardBox id={cardId} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle startText={title} endText={highlightText} />
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.current}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId, formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + highlightText) })}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              clickableLegend: false,
              mousePointer: true,
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox>
      {show_previous ? <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle color="cta" startText={title} endText={prevHighlightText} />,
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.prev}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId: cardId+'prev', formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + prevHighlightText)})}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              mousePointer: true,
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox> : null}
    </WidgetCardBox>)
};

function TopEventsChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions, show_previous, prevHighlightText} = props;
  const data = React.useMemo(() => {
      const current = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr1_totals`]), v => ({
            year: group_by === 'year' ? v[`gr1`] : v[`gr1_group_by`].split(' ')[2],
            group_by: v[`gr1`],
            totals: _.parseInt(v[`gr1_totals`]),
            [`${totals_by}_name`]: v[`gr1_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr1_${totals_by}_id`],
            ...(v[`gr1_subData`] && {gr_subData : v[`gr1_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const prev = _.orderBy(
        _.map(_.filter(chartData, v => v[`gr2_totals`]), v => ({
          year: group_by ==='year' ? v[`gr2`] : v[`gr2_group_by`].split(' ')[2],
            group_by: v[`gr2`],
            totals: _.parseInt(v[`gr2_totals`]),
            [`${totals_by}_name`]: v[`gr2_${totals_by}_name`],
            [`${totals_by}_id`]: v[`gr2_${totals_by}_id`],      
            ...(v[`gr2_subData`] && {gr_subData : v[`gr2_subData`]})
        })),
        [v => parseInt(v[`totals`])],
        ['desc']
      );
      const current_others_idx = current.findIndex(v => v[`${totals_by}_name`] === 'Others');
      const prev_others_idx = prev.findIndex(v => v[`${totals_by}_name`] === 'Others');
      return {
        current: [
          ...(current_others_idx > -1 ? current.slice(0, current_others_idx) : []),
          ...(current_others_idx > -1 ? current.slice(current_others_idx+1) : current),
          ...(current_others_idx > -1 ? [current[current_others_idx]] : []),
        ],
        prev: [
          ...(prev_others_idx > -1 ? prev.slice(0, prev_others_idx) : []),
          ...(prev_others_idx > -1 ? prev.slice(prev_others_idx+1) : prev),
          ...(prev_others_idx > -1 ? [prev[prev_others_idx]] : []),
        ],
      };
    }, [chartData, division_id]);
    return (<WidgetCardBox colSizes={{xs: 12, lg: 12}}  >
      <WidgetCardBox id={cardId} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle startText={title} endText={highlightText} />
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.current}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId, formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + highlightText) })}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              clickableLegend: false,
              mousePointer: true,
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox>
      {show_previous ? <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} boxStyle={{boxShadow:'none'}} components={{
        Title: <WidgetTitle color="cta" startText={title} endText={prevHighlightText} />,
      }}>
        <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
          <Chart type="pie"
            data={data.prev}
            height="480px"
            isExportable={true}
            modifyExport={modifyExport({cardId: cardId+'prev', formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + prevHighlightText)})}
            onChartReady={(chart, data) => createPieChart(chart, {
              data,
              value: `totals`,
              category: `${totals_by}_name`,
              showSliceLabel: false,
              legendScrollable: true,
              mousePointer: true,
              ...chartOptions,
            })} />
        : null}</Col>
      </WidgetCardBox> : null}
    </WidgetCardBox>)
}
function TopRepsChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions,tenant_id,show_previous,prevHighlightText,titleSelect,showSidepanel,getSidepanelData } = props;
  const data = React.useMemo(() => ({
    current: _.orderBy(_.filter((chartData||[]), v => v[`gr1_totals`] && v[`gr1_${totals_by}_name`] !== 'Others'), [v => parseInt(v.gr1_totals)], ['asc']),
    prev: _.orderBy(_.filter((chartData||[]), v => v[`gr2_totals`] && v[`gr2_${totals_by}_name`] !== 'Others'), [v => parseInt(v.gr2_totals)], ['asc'])
  }), [chartData, totals_by, tenant_id, division_id]);
const maximum= {};// based on asc sort in data, if sort changes, use Math.max to find maximum 
maximum[`gr1_${totals_by}_max`] = data.current.length > 0 ? Math.ceil(data.current[data.current.length-1][`gr1_totals`])  : 0;
maximum[`gr2_${totals_by}_max`] = data.prev.length > 0 ? Math.ceil(data.prev[data.prev.length-1][`gr2_totals`]) : 0;
maximum[`gr_${totals_by}_max`] =   maximum[`gr1_${totals_by}_max`]  > maximum[`gr2_${totals_by}_max`] ? maximum[`gr1_${totals_by}_max`]  : maximum[`gr2_${totals_by}_max`] 
return (<WidgetCardBox colSizes={{xs: 12, lg: 12}}
  components={{
    Title:titleSelect && totals_by !== "product" ? <TitleSelect title = {'Top Reps'}  {...titleSelect}/>
    : null
  }}>
    <WidgetCardBox id={cardId} colSizes={colSizes} boxHeight ={{height: isLoading ? '' : '480px'}} boxStyle={{boxShadow:'none', overflowY : chartData && data.current.length >=7 ?'auto' : 'hidden' }} 
    components={{
      Title:  <WidgetTitle  startText={ totals_by === "product" ? title : highlightText}  endText={totals_by === "product" ? highlightText : ""} />
    }}>
      <Col xs style={{
        textAlign: isLoading ? 'center': 'initial'
      }}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          data={data.current}
          height={calcChartHeight(data.current.length <= 3 ? 3 : data.current.length + 1, 1, 20*70, 20) + "px"}
          isExportable={true}
          modifyExport={modifyExport({cardId, totals_by, group_by, formatNumberType, fileName: totals_by === "product" ? (title + '_' + highlightText) : ('Top Reps_' + highlightText)})}
          onChartReady={(chart, data) => createBulletImageBarChart(chart, {
            data,
            valueX: `gr1_totals`,
            valueY: `gr1_${totals_by}_id`,
            valueText: totals_by === "product" ? `\u00A0\u00A0\u00A0{gr1_${totals_by}_name}` : `\u00A0\u00A0\u00A0{gr1_${totals_by}_name} - {gr1_tenant_name}`,
            imgField: `gr1_img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            differentOpacity :true,
            tooltipPointerOrientation:"down",
            tooltipText: "{tooltipText}",
            insideBarLabelText: "{valueX}",
            mousePointer : true, 
            scaleMax:maximum[`gr_${totals_by}_max`],
            autoAdjustHeight: true,
            hideValueAxis: true,
            onHit: (e) => {
              const data = e.target.dataItem.dataContext;
              if (showSidepanel && getSidepanelData) {
                showSidepanel(getSidepanelData(data, 'gr1'));
              } else if ( data && data[`gr1_${totals_by}_id`]) {
                 //check if user is active, if not show tooltip
                 if (data['isActive'] == 1) {
                  window.open(`/user.php?id=${data[`gr1_${totals_by}_id`]}`, '_blank');
                  } 
              }
            },
            ...chartOptions,
          })} />
        : null}</Col>
    </WidgetCardBox>
    {show_previous ? 
    <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} boxHeight={{  height: isLoading ? '' :'480px'}} boxStyle={{boxShadow:'none', overflowY: chartData && data.prev.length >= 7 ? 'auto' : 'hidden'}} components={{
      Title: <WidgetTitle startText={totals_by === "product" ? title : prevHighlightText} endText={totals_by === 'product' ? prevHighlightText : ''}/>,
    }}>
      <Col xs style={{
        textAlign: isLoading ? 'center': 'initial'
      }}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          data={data.prev}
          height={calcChartHeight(data.prev.length <= 3 ? 3 : data.prev.length + 1, 1, 20*70, 20) + "px"}
          isExportable={true}
          modifyExport={modifyExport({cardId: cardId+'prev', totals_by, group_by, formatNumberType, fileName: totals_by === "product" ? (title + '_' + prevHighlightText) : ('Top Reps_' + prevHighlightText)})}
          onChartReady={(chart, data) => createBulletImageBarChart(chart, {
            data,
            valueX: `gr2_totals`,
            valueY: `gr2_${totals_by}_id`,
            valueText: totals_by === "product" ? `\u00A0\u00A0\u00A0{gr2_${totals_by}_name}` : `\u00A0\u00A0\u00A0{gr2_${totals_by}_name} - {gr2_tenant_name}`,
            imgField: `gr2_img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            tooltipPointerOrientation:"down",
            differentOpacity : true,
            tooltipText: "{tooltipText}",
            mousePointer: true,
            insideBarLabelText: "{valueX}", 
            scaleMax: maximum[`gr_${totals_by}_max`],
            hideValueAxis: true,
            autoAdjustHeight: true,
            onHit: (e) => {
              const data = e.target.dataItem.dataContext;
              if (showSidepanel && getSidepanelData) {
                showSidepanel(getSidepanelData(data, 'gr2'));
              } else if ( data && data[`gr2_${totals_by}_id`]) {
                if (data['isActive'] == 1) {
                  window.open(`/user.php?id=${data[`gr2_${totals_by}_id`]}`, '_blank');
                  } 
              }
            },
            ...chartOptions,
          })} />
      : null}</Col>
    </WidgetCardBox> : null}
  </WidgetCardBox>);
}
function TopQuotingPreSalesRepsChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions,
    tenant_id,show_previous,prevHighlightText,titleSelect,showSidepanel,getSidepanelData } = props;
  const data = React.useMemo(() => ({
    current: _.orderBy(_.filter((chartData||[]), v => v[`gr1_totals`] && v[`gr1_${totals_by}_name`] !== 'Others'), [v => parseInt(v.gr1_totals)], ['asc']),
    prev: _.orderBy(_.filter((chartData||[]), v => v[`gr2_totals`] && v[`gr2_${totals_by}_name`] !== 'Others'), [v => parseInt(v.gr2_totals)], ['asc'])
  }), [chartData, totals_by, tenant_id, division_id]);
const maximum= {};// based on asc sort in data, if sort changes, use Math.max to find maximum 
maximum[`gr1_${totals_by}_max`] = data.current.length > 0 ? Math.ceil(data.current[data.current.length-1][`gr1_totals`])  : 0;
maximum[`gr2_${totals_by}_max`] = data.prev.length > 0 ? Math.ceil(data.prev[data.prev.length-1][`gr2_totals`]) : 0;
maximum[`gr_${totals_by}_max`] =   maximum[`gr1_${totals_by}_max`]  > maximum[`gr2_${totals_by}_max`] ? maximum[`gr1_${totals_by}_max`]  : maximum[`gr2_${totals_by}_max`] 
return (<WidgetCardBox colSizes={{xs: 12, lg: 12}}
  components={{
    Title:titleSelect && totals_by !== "product" ? <TitleSelect title = {'Top Quoting Reps'}  {...titleSelect}/>
    : null
  }}>
    <WidgetCardBox id={cardId} colSizes={colSizes} boxHeight ={{height: isLoading ? '' : '480px'}} boxStyle={{boxShadow:'none', overflowY : chartData && data.current.length >=7 ?
    'auto' : 'hidden' }} 
    components={{
      Title:  <WidgetTitle  startText={ totals_by === "product" ? title : highlightText}  endText={totals_by === "product" ? highlightText : ""} />
    }}>
      <Col xs style={{
        textAlign: isLoading ? 'center': 'initial'
      }}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          data={data.current}
          height={calcChartHeight(data.current.length <= 3 ? 3 : data.current.length + 1, 1, 20*70, 20) + "px"}
          isExportable={true}
          modifyExport={modifyExport({cardId, totals_by, group_by, formatNumberType, fileName: totals_by === "product" ? (title + '_' + highlightText) : ('Top Reps_' + highlightText)})}
          onChartReady={(chart, data) => createBulletImageBarChart(chart, {
            data,
            valueX: `gr1_totals`,
            valueY: `gr1_${totals_by}_id`,
            valueText: totals_by === "product" ? `\u00A0\u00A0\u00A0{gr1_${totals_by}_name}` : `\u00A0\u00A0\u00A0{gr1_${totals_by}_name} - {gr1_tenant_name}`,
            imgField: `gr1_img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            differentOpacity :true,
            tooltipPointerOrientation:"down",
            tooltipText: "{tooltipText}",
            insideBarLabelText: "{valueX}",
            mousePointer : true, 
            scaleMax:maximum[`gr_${totals_by}_max`],
            autoAdjustHeight: true,
            hideValueAxis: true,
            onHit: (e) => {
              const data = e.target.dataItem.dataContext;
              if (showSidepanel && getSidepanelData) {
                showSidepanel(getSidepanelData(data, 'gr1'));
              } else if ( data && data[`gr1_${totals_by}_id`]) {
                 //check if user is active, if not show tooltip
                 if (data['isActive'] == 1) {
                  window.open(`/user.php?id=${data[`gr1_${totals_by}_id`]}`, '_blank');
                  } 
              }
            },
            ...chartOptions,
          })} />
        : null}</Col>
    </WidgetCardBox>
    {show_previous ? 
    <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} boxHeight={{  height: isLoading ? '' :'480px'}} boxStyle={{boxShadow:'none', overflowY: chartData && data.prev.length >= 7 ? 'auto' : 'hidden'}} components={{
      Title: <WidgetTitle startText={totals_by === "product" ? title : prevHighlightText} endText={totals_by === 'product' ? prevHighlightText : ''}/>,
    }}>
      <Col xs style={{
        textAlign: isLoading ? 'center': 'initial'
      }}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          data={data.prev}
          height={calcChartHeight(data.prev.length <= 3 ? 3 : data.prev.length + 1, 1, 20*70, 20) + "px"}
          isExportable={true}
          modifyExport={modifyExport({cardId: cardId+'prev', totals_by, group_by, formatNumberType, fileName: totals_by === "product" ? (title + '_' + prevHighlightText) : ('Top Reps_' + prevHighlightText)})}
          onChartReady={(chart, data) => createBulletImageBarChart(chart, {
            data,
            valueX: `gr2_totals`,
            valueY: `gr2_${totals_by}_id`,
            valueText: totals_by === "product" ? `\u00A0\u00A0\u00A0{gr2_${totals_by}_name}` : `\u00A0\u00A0\u00A0{gr2_${totals_by}_name} - {gr2_tenant_name}`,
            imgField: `gr2_img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            tooltipPointerOrientation:"down",
            differentOpacity : true,
            tooltipText: "{tooltipText}",
            mousePointer: true,
            insideBarLabelText: "{valueX}", 
            scaleMax: maximum[`gr_${totals_by}_max`],
            hideValueAxis: true,
            autoAdjustHeight: true,
            onHit: (e) => {
              const data = e.target.dataItem.dataContext;
              if (showSidepanel && getSidepanelData) {
                showSidepanel(getSidepanelData(data, 'gr2'));
              } else if ( data && data[`gr2_${totals_by}_id`]) {
                if (data['isActive'] == 1) {
                  window.open(`/user.php?id=${data[`gr2_${totals_by}_id`]}`, '_blank');
                  } 
              }
            },
            ...chartOptions,
          })} />
      : null}</Col>
    </WidgetCardBox> : null}
  </WidgetCardBox>);
}


function PurchaseOrdersChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions,isSparkline,totalsChart } = props;
  const gr_by = group_by === 'quarter' ? `[Q${currentQuarter} ${currentYear}]`
  : group_by === 'year' ? currentYear
  : `[${months[currentMonth]} ${currentYear}]`;
  const data = React.useMemo(() => {
  const _data = group_by === 'year' ? _.values(chartData) 
  : _.filter(_.values(chartData), v => {
  const val = (v.y1 !== undefined || v.y1 !== null || v.y1 !== '') ? v.y1_group_by : v.y2_group_by;
  return group_by === 'month'
  ? months.indexOf(val) <= currentMonth
  : group_by === 'quarter'
  ? quarters.indexOf(val)+1 <= currentQuarter
  : true;
  });
  return {
  data: group_by === 'month'
  ? _.orderBy(_data, [v => months.indexOf(v.group_by.split(' ')[0])])
  : group_by === 'quarter'
  ? _.orderBy(_data, [v => quarters.indexOf(v.group_by.split(' ')[0])])
  : _data
  };

  }, [chartData, group_by, division_id])
  return (
  <WidgetCardBox id={cardId} colSizes={colSizes} components={{Title: <WidgetTitle startText={title} />,}}>
  <Col xs={12} style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
  <Chart type="bar" height={isSparkline ? "140px" : "300px"} width="100%"
  data={data.data}
  onChartReady={totalsChart({isSparkline, thisYr: false})}
  isExportable={true}
  modifyExport={modifyExport({cardId, totals_by, group_by, formatNumberType, fileName: title})}
  // exportPdf={exportTotalsPdf({ formatNumberType, totals_by, group_by, title, type, })}
  />
  : null}</Col>
  </WidgetCardBox>
  );
}

function DonutChart(props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions,show_previous } = props;
  const data = React.useMemo(() => ({
    current: _.orderBy(
      _.map(_.filter(chartData, v => v[`gr1_totals`]), v => ({
        year: group_by === 'year' ? v[`gr1`] : v[`gr1_group_by`].split(' ')[2],
        group_by: v[`gr1`],
        totals: _.parseInt(v[`gr1_totals`]),
        [`${totals_by}_name`]: v[`gr1_${totals_by}_name`],
      })),
      [v => parseInt(v[`totals`])],
      ['desc']
    ),
    prev: _.orderBy(
      _.map(_.filter(chartData, v => v[`gr2_totals`]), v => ({
        year: group_by === 'year' ? v[`gr2`] : v[`gr2_group_by`].split(' ')[2],
        group_by: v[`gr2`],
        totals: _.parseInt(v[`gr2_totals`]),
        [`${totals_by}_name`]: v[`gr2_${totals_by}_name`],
      })),
      [v => parseInt(v[`totals`])],
      ['desc']
    )
  }), [chartData, totals_by, division_id]);
  return (<>
    <WidgetCardBox id={cardId} colSizes={colSizes} components={{
      Title: <WidgetTitle startText={title} endText={highlightText} />
    }}>
      <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="pie"
          data={data.current}
          height="480px"
          isExportable={true}
          modifyExport={modifyExport({cardId, formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + highlightText)})}
          onChartReady={(chart, data) => createDonutChart(chart, {
            data,
            value: `totals`,
            category: `${totals_by}_name`,
            radius:50,
            ...chartOptions,
          })} />
      : null}</Col>
    </WidgetCardBox>
    {show_previous ? <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} components={{
      Title: <WidgetTitle startText={title} endText={prevHighlightText} />
    }}>
      <Col xs style={{textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="pie"
          data={data.current}
          height="480px"
          isExportable={true}
          modifyExport={modifyExport({cardId: cardId+'prev', formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + highlightText)})}
          onChartReady={(chart, data) => createDonutChart(chart, {
            data,
            value: `totals`,
            category: `${totals_by}_name`,
            radius:50,
            ...chartOptions,
          })} />
      : null}</Col>
    </WidgetCardBox> : null}
  </>);
}

function BarBulletVerticalChart (props) {
  const {chartData, division_id, cardId, colSizes, title, highlightText, isLoading,formatNumberType, totals_by, group_by,chartOptions,show_previous ,prevHighlightText} = props;
  const data = React.useMemo(() => ({
    current: _.orderBy(
      _.map(_.filter(chartData||[], v => v[`gr1_totals`] && v[`gr1_${totals_by}_name`] !== 'Others' && _.has(v, `gr1_${totals_by}_name`)), v => ({
        year: group_by === 'year' ? v[`gr1`] : v[`gr1_group_by`].split(' ')[2],
        group_by: v[`gr1`],
        totals: _.parseInt(v[`gr1_totals`]),
        [`${totals_by}_name`]: v[`gr1_${totals_by}_name`],
        img_src: v[`gr1_img_src`],
      })),
      [v => parseInt(v.totals)],
      ['desc']
    ),
    prev: _.orderBy(
      _.map(_.filter(chartData||[], v => v[`gr2_totals`] && v[`gr2_${totals_by}_name`] !== 'Others' && _.has(v, `gr2_${totals_by}_name`)), v => ({
        year: group_by === 'year' ? v[`gr2`] : v[`gr2_group_by`].split(' ')[2],
        group_by: v[`gr2`],
        totals: _.parseInt(v[`gr2_totals`]),
        [`${totals_by}_name`]: v[`gr2_${totals_by}_name`],
        img_src: v[`gr2_img_src`],
      })),
      [v => parseInt(v.totals)],
      ['desc']
    )
  }), [chartData, totals_by, tenant_id, division_id]);
  return (<>
    <WidgetCardBox id={cardId} colSizes={colSizes} components={{
      Title: <WidgetTitle startText={title} endText={highlightText} />,
    }}>
      <Col xs style={{maxHeight: 400, overflow: 'auto', textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          width={data.current && data.current.length > 1 ? `${data.current.length * 70}px` : "100%"}
          height="380px"
          data={data.current}
          isExportable={true}
          modifyExport={modifyExport({cardId, formatNumberType, totals_by, group_by, onlyValTransform: true, fileName: (title + '_' + prevHighlightText)})}
          onChartReady={(chart, data) => createVerticalBulletImageBarChart(chart, {
            data,
            valueY: `totals`,
            valueX: `${totals_by}_name`,
            imgField: `img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            tooltipText: `{group_by}: [bold]{valueY.value}`,
            ...chartOptions,
          })} />
        : null}</Col>
    </WidgetCardBox>
    {show_previous ? <WidgetCardBox id={cardId+'prev'} colSizes={colSizes} components={{
      Title: <WidgetTitle color="cta" startText={title} endText={prevHighlightText} />,
    }}>
      <Col xs style={{maxHeight: 400, overflow: 'auto', textAlign: isLoading ? 'center': 'initial'}}>{isLoading ? <Loading height={40} /> : chartData ?
        <Chart type="bar"
          width={data.prev && data.prev.length > 1 ? `${data.prev.length * 70}px` : "100%"}
          height="380px"
          data={data.prev}
          isExportable={true}
          modifyExport={modifyExport({cardId: cardId+'prev', formatNumberType, totals_by, group_by, onlyValTransform: true,fileName: (title + '_' + prevHighlightText)})}
          onChartReady={(chart, data) => createVerticalBulletImageBarChart(chart, {
            data,
            valueY: `totals`,
            valueX: `${totals_by}_name`,
            imgField: `img_src`,
            imgHeight: 50,
            imgWidth: 50,
            fontSize: 13,
            tooltipText: `{group_by}: [bold]{valueY.value}`,
            ...chartOptions,
          })} />
        : null}</Col>
    </WidgetCardBox> : null}
  </>);
}

export function SupplierStatsChart({
  type,
  showPopup,
  totals_by='totals',
  group_by='year',
  colSizes={xs: 12, lg: 6},
  tenant_id,
  division_id,
  ...props
}) {
  const supplierPOs = useSelector(getSupplierPOStats);
  const supplierEvents = useSelector(getSupplierEventStats);
  const supplierProducts = useSelector(getSupplierProductsStats);
  const supplierPresales = useSelector(getSupplierPresalesStats);
  const highlightText = group_by === 'quarter' ? 'Q' + currentQuarter
    : group_by === 'month' ? fullMonths[currentMonth]
    : currentYear;
  const prevHighlightText = group_by === 'quarter' ? 'Q' + prevQuarter
    : group_by === 'month' ? fullMonths[prevMonth]
    : currentYear-1;
  // group_by[0].toUpperCase() + group_by.slice(1);

  const formatNumberType = ['tags', 'tenants_count'].includes(totals_by)
    ? 'percentage' : [ 'order_rep', 'order_client_rep'].includes(totals_by) && !type.includes('SUPPLIER_PRESALES') ? 'roundedCurrency': type.includes('SUPPLIER_POS') ? 'currency' : 'number';

  const chartOptions = { formatNumber: getChartNumberFormatOption(formatNumberType) };
  const title = getWidgetTitle(type, totals_by, props.title || '');
  const statsData = type.includes('SUPPLIER_POS')
    ? supplierPOs
    : type.includes('SUPPLIER_EVENTS')
    ? supplierEvents
    : type.includes('SUPPLIER_PRODUCTS')
    ? supplierProducts
    : type.includes('SUPPLIER_PRESALES')
    ? supplierPresales
    : {};
  const chartData = _.get(statsData, `totals_by_${totals_by}.data`, false);
  const cardId = type+props.chart_type+group_by+totals_by;
  const isLoading = useSelector(({dashboard}) => {
    return dashboard.loading[`${type}_${totals_by}_${group_by}`] || dashboard.loading.supplierStats;
  });
 
  const totalsChart = ({thisYr=false, isSparkline=false, isPercentage=false, ...options}) => {
    const createChart = isPercentage ? createLineChart : createBarChart;
    const years = thisYr
      ? group_by == 'year'
        ? [currentYear+'', (currentYear-1)+'']
        : [currentYear+'']
      : _.sortBy(statsData[`totals_by_${totals_by}`].years);
    return (chart, data) => createChart(chart, {
      chartColors: [...totalsChartsColors],
      isSparkline,
      cursorLineX:false,
      cursorLineY:false,
      xAxes: [{
       category: group_by == 'year' ? 'year' : isPercentage ? 'y2_group_by' : 'categoryX',
        title: _.get(_.find(selectByOptions, group_by), 'label', ''),
        showGrid: isPercentage,
        showAxis: true,
        showTitle: true,
        showLabel: true,
        cursorTooltipEnabled:false,
        cellStartLocation: isPercentage ? 0.5 : 0.1,
        cellEndLocation: isPercentage ? 0.6 : 0.9,
        maxWidth: 70,
        darkerGridLines: true,
      }],
      yAxes: [{
        cursorTooltipEnabled: true,
        title: isPercentage ? "Total Percent" : "Total",
        // minGridDistance: isSparkline && isPercentage ? 20 : false,
        // min: !isPercentage ? 0 : false,
        // max: isPercentage ? 1.10 : false,
        renderOutside: true,
        min: 0,
        max: isPercentage ? 1 : undefined,
        strictMinMax: true,
        showGrid: isPercentage,
        showAxis: isPercentage,
        showTitle: isPercentage,
        showLabel: true,
        cursorTooltipEnabled:false,
        keepSelection: true,
        darkerGridLines: true
      }],
      series: isPercentage && group_by == 'year' ? [{
        categoryX: `group_by_percentage`,
        valueY: `totals_percentage`,
        name: "Years",
        tooltipText: `${group_by == 'year' ? '' : '{year} - '}{categoryX}: [bold]{valueY}`,
        strokeWidth: 0,
        fillPropertyOpacity: 'opacity',
      }]
      : group_by == 'year' ? [{
        categoryX: 'year',
        valueY: 'totals',
        name: "Years",
        strokeWidth: 0,
        tooltipText: `{year}: [bold]{valueY}`,
        fillPropertyOpacity: 'opacity',
        fillDifferentColors: true
     }]
    : years.map((v, i) => {
        return ({
          categoryX: `y${thisYr ? i+1 : years.length - i}_group_by${isPercentage ? '_percentage' : ''}`,
          valueY: `y${thisYr ? i+1 : years.length - i}_totals${isPercentage ? '_percentage' : ''}`,
          name: v,
          tooltipText: `${group_by == 'year' ? '' : '{year} - '}{categoryX}: [bold]{valueY}`,
          strokeWidth: 0,
          fillPropertyOpacity: 'opacity',
        });
      }),
      tooltipText: `${group_by == 'year' ? '' : '{year} - '}{categoryX}: [bold]{valueY}`,
      legend: group_by === 'year' ? false : true,
      isLegendCheckbox: true,
      isSupplierDashboardPoChart: true,
      ...chartOptions,
      ...options
    });
  };

  const isSparkline = _.get(props, 'isSparkline', false);
  if (totals_by === 'totals' && props.chart_type === 'bar') {
    return <PurchaseOrdersChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
    isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions} isSparkline={isSparkline} totalsChart={totalsChart}/>
  } else if (["tags", "tenants_count"].includes(totals_by) && props.chart_type == 'line') {
      return <PercentageOfDistributorsChart  
      chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
      isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions}
      statsData={statsData} showPopup={showPopup} isSparkline={isSparkline} totalsChart={totalsChart} titleSelect={props.titleSelect}/>
  } else if (totals_by && props.chart_type === 'bar_bullet' && type !== "SUPPLIER_PRESALES" && type !== "SUPPLIER_PRODUCTS") {
      return <TopRepsChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
      isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions}
      show_previous ={props.show_previous} prevHighlightText={prevHighlightText} titleSelect={props.titleSelect} showSidepanel={props.showSidepanel} getSidepanelData={props.getSidepanelData}/>
  } else if((type == "SUPPLIER_PRESALES" || type =="SUPPLIER_PRODUCTS") && props.chart_type === 'bar_bullet') {
    return <TopQuotingPreSalesRepsChart  chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
    isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions}
    show_previous ={props.show_previous} prevHighlightText={prevHighlightText} titleSelect={props.titleSelect} showSidepanel={props.showSidepanel} getSidepanelData={props.getSidepanelData}/>
  }
   else if (totals_by && props.chart_type === 'donut') {
      return <DonutChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
      isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions}  show_previous ={props.show_previous} />
  }else if (totals_by === 'industry' && props.chart_type === 'pie') {
    return <TopIndustriesChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
    isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions} 
    show_previous ={props.show_previous} prevHighlightText={prevHighlightText} />
  } else if (totals_by === 'events' && props.chart_type === 'pie') {
    return <TopEventsChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
    isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions} 
    show_previous ={props.show_previous} prevHighlightText={prevHighlightText} />
  }
  else if (totals_by === 'tenant' && props.chart_type === 'pie') {
      return <TopDistributorsChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText}
      isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions} 
      show_previous ={props.show_previous} prevHighlightText={prevHighlightText} />
  } else if (totals_by && props.chart_type === 'bar_bullet_vertical') {
      return <BarBulletVerticalChart chartData={chartData} division_id={division_id} cardId={cardId} colSizes={colSizes} title={title}  highlightText={highlightText} 
      isLoading ={isLoading} formatNumberType={formatNumberType} totals_by={totals_by} group_by={group_by} chartOptions={chartOptions}  show_previous ={props.show_previous} prevHighlightText={prevHighlightText}  />
  }

  return null;
}

const commonPropTypes = {
  type: PropTypes.oneOf(['SUPPLIER_POS', 'SUPPLIER_EVENTS', 'SUPPLIER_PRESALES', 'SUPPLIER_PRODUCTS']).isRequired,
  chart_type: PropTypes.string.isRequired,
  totals_by: PropTypes.oneOf(['totals', 'tenant', 'product', 'order_rep', 'order_client_rep', 'tags', 'tenants_count', 'industry' , 'events']),
  group_by: PropTypes.oneOf(['year', 'month', 'quarter']),
  compare_previous: PropTypes.oneOf([1, 0]),
  tenant_id: PropTypes.string,
  showPopup: PropTypes.func,
  showSidepanel: PropTypes.func,
};
SupplierStatsChart.propTypes = commonPropTypes;

export default SupplierStatsChart;
